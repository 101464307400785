import React from "react";

// HomePage pages.old
// Pages
// Blog
// Portfolio
// Shops
// Elements
import Routers from "./route";

function App() {
    return (
        <>
            <Routers/>
        </>
        // <Router basename={"/"}>
        //     <ScrollToTop>
        //         <Switch>
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/`} component={Demos} />*/}
        //             {/*<Route exact path={`${process.env.PUBLIC_URL}/`} component={MinimalDesign}/>*/}
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/creative-agency`}
        //                 component={CreativeAgency}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/small-business`}
        //                 component={SmallBusiness}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/corporate-business`}
        //                 component={CorporateBusiness}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/startup-business`}
        //                 component={StartupBusiness}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/minimal-design`}
        //                 component={MinimalDesign}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/creative-onepage`}
        //                 component={CreativeOnePage}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/creative-portfolio`}
        //                 component={CreativePortfolio}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/personal-resume`}
        //                 component={PersonalResume}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/seo-agency`}
        //                 component={SEOAgency}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/digital-agency`}
        //                 component={DigitalAgency}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/architecture`}
        //                 component={Architecture}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/restaurant`}
        //                 component={Restaurant}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/online-shop`}
        //                 component={OnlineShop}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/photography`}
        //                 component={Photography}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/gym-fitness`}
        //                 component={GymFitness}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/app-style`}
        //                 component={AppStyle}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/travel-agency`}
        //                 component={TravelAgency}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/construction`}
        //                 component={Construction}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/music`}
        //                 component={Music}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/weddings`}
        //                 component={Weddings}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/design-studio`}
        //                 component={DesignStudio}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/about-us`}
        //                 component={Index}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/about`}
        //                 component={Index}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/about-creative`}
        //                 component={AboutCreative}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/service`}
        //                 component={ServiceSimple}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/service-simple`}
        //                 component={ServiceSimple}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/service-creative`}
        //                 component={ServiceCreative}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/contact-simple`}
        //                 component={ContactSimple}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/contact`}
        //                 component={ContactSimple}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/contact-creative`}
        //                 component={ContactCreative}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/404-page`}
        //                 component={Page404}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/coming-soon`}
        //                 component={ComingSoon}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/faqs`}
        //                 component={FAQs}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/term-condition`}
        //                 component={TermCondition}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/privacy`}
        //                 component={Privacy}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/career`}
        //                 component={Career}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/login`}
        //                 component={Login}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/blog/:blogID`}
        //                 component={BlogStandard}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/events`}
        //                 component={EventsGrid}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/blog-grid`}
        //                 component={BlogGrid}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/blog-sidebar`}
        //                 component={BlogSidebar}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/blog-masonry`}
        //                 component={BlogMasonry}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/blog-carousel`}
        //                 component={BlogCarousel}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/blog-sidebar-right`}
        //                 component={BlogSidebarRight}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/blog-sidebar-left`}
        //                 component={BlogSidebarLeft}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/box-2-columns`}
        //                 component={PortfolioBoxedTwo}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/box-3-columns`}
        //                 component={PortfolioBoxedThree}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/box-4-columns`}
        //                 component={PortfolioBoxedFour}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/box-space-2-columns`}
        //                 component={PortfolioBoxedSpaceTwo}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/box-space-3-columns`}
        //                 component={PortfolioBoxedSpaceThree}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/box-space-4-columns`}
        //                 component={PortfolioBoxedSpaceFour}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/wide-2-columns`}
        //                 component={PortfolioWideTwo}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/wide-3-columns`}
        //                 component={PortfolioWideThree}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/wide-4-columns`}
        //                 component={PortfolioWideFour}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/wide-space-2-columns`}
        //                 component={PortfolioWideSpaceTwo}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/wide-space-3-columns`}
        //                 component={PortfolioWideSpaceThree}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/gallery`}
        //                 component={Gallery}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/wide-space-4-columns`}
        //                 component={PortfolioWideSpaceFour}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/single-portfolio`}
        //                 component={SinglePortfolio}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/shop-fullwidth`}
        //                 component={ShopFullwidth}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/shop-standard`}
        //                 component={ShopStandard}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/shop-boxed`}
        //                 component={ShopBoxed}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/shop-masonry`}
        //                 component={ShopMasonry}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/shop-product`}
        //                 component={ShopSingleProduct}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/shop-cart`}
        //                 component={ShopCart}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/shop-checkout`}
        //                 component={ShopCheckout}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/buttons`}
        //                 component={Buttons}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/typography`}
        //                 component={Typography}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/alerts`}
        //                 component={Alerts}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/pricing-tables`}
        //                 component={PricingTables}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/call-to-action`}
        //                 component={CallToActions}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/parallax-section`}
        //                 component={ParallaxSections}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/tabs`}
        //                 component={Tabs}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/accordions`}
        //                 component={Accordions}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/sliders`}
        //                 component={Sliders}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/progress-bar`}
        //                 component={ProgressBars}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/contact-form`}
        //                 component={ContactForms}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/google-maps`}
        //                 component={GoogleMaps}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/progress-bar-ring`}
        //                 component={ProgressBarRing}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/theme-icons`}
        //                 component={ThemeIcons}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/boxed-icons`}
        //                 component={BoxedIcons}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/grid-columns`}
        //                 component={GridColumns}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/team-member`}
        //                 component={TeamMembers}
        //             />
        //             <Route
        //                 exact
        //                 path={`${process.env.PUBLIC_URL}/countdowns`}
        //                 component={Countdowns}
        //             />
        //             <Route component={Page404}/>
        //         </Switch>
        //     </ScrollToTop>
        // </Router>
    );
}

export default App;
