import React from "react";
import WelcomeSubsection from "./WelcomeSubsection";
import WelcomeFeatures from "./WelcomeFeatures";
import image from "../../assets/images/wsc/homepage-section-1.png";
import TeamSlider from "../Team/TeamSlider";
import CountersThree from "../Counters/CountersThree";

const WelcomeTwo = ({toggler, handleToggler}) => (
    <section className="dark-bg pt-0 pb-0">
        <WelcomeSubsection
            title="We are Welding Study Center"
            tagline="- - - "
            image={image}
            textButton="Watch our video"
            toggler={toggler}
            handleToggler={handleToggler}
        >
            PT. Pusat Pelatihan Kompetensi Mandiri
            (Welding Study Center) is an Authorized
            Training Body from Indonesia that is approved
            by International Institute of Welding (IIW). In
            providing services WSC cooperate with
            Indonesia Welding Society (API/IWS),
            KEMNAKER, BNSP, MIGAS, BKI, DNVGL, etc. WSC
            is a subsidiary of SaVa Group – also holding
            company for PT. Gamma Buana Persada.
            For a person who wants to be professionally
            certified and expert in welding, metallurgical
            engineering, inspection and whole life integrity
            management. Welding Study Center one of the
            independent international training body and
            research technology organizations by
            continuing span of innovation. With our expert
            and experienced professional sources to share
            knowledge and to improve the people's skill and
            provide the best solution.
        </WelcomeSubsection>
        <CountersThree type="wide"/>

        <div className="container-fluid">
            <div className="row row-flex flex-center">
                {/*<HeadingThree*/}
                {/*    title="Our Skills"*/}
                {/*    tagline="We develop big ideas that sell"*/}
                {/*>*/}
                {/*    <OurSkillsOne classAppend="cardo-font" font="cardo-font"/>*/}
                {/*</HeadingThree>*/}
                <div className="col-md-4 col-sm-12 team-member-wrapper">
                    <div className="col-inner">
                        <TeamSlider/>
                    </div>
                </div>
                <WelcomeFeatures
                    title="What We Do"
                    tagline="-- -- --"
                />
            </div>
        </div>
    </section>
);

export default WelcomeTwo;
