import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitlePortfolio from "../../components/PageTitle/PageTitlePortfolio";
import Portfolio from "../../components/Portfolio/Portfolio";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useNavigate} from "react-router-dom";

const GalleryPage = () => {
    const navigate = useNavigate()

    const scrollToSection = (e, content) => {
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/${content}`)
    };

    return (
        <Loader>
            <HeaderTwo scrollToSection={scrollToSection}/>
            <PageTitlePortfolio
                title="Gallery"
                tagline="- - -"
            />
            <section className="pt-100 pt-100">
                <Portfolio filter="true" columns="3" layout="wide" space="true"/>
            </section>
            <ClientsBrand/>
            <FooterOne/>
        </Loader>
    );
}

export default GalleryPage;
