import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleContact from "../../components/PageTitle/PageTitleContact";
import ContactThree from "../../components/ContactUs/ContactThree";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import Map from "../../components/Maps/Map";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useNavigate} from "react-router-dom";

const ContactUsPage = () => {

    const navigate = useNavigate()

    const scrollToSection = (e, content) => {
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/${content}`)
    };

    return (
        <Loader>
            <HeaderTwo scrollToSection={scrollToSection}/>
            <PageTitleContact
                title="Contact Us"
                tagline="Customer Support For Welding Study Center"
            />
            <ContactThree/>
            <section className="pt-0 pb-0 map-section">
                <Map classAppend="wide"/>
            </section>
            <ClientsBrand/>
            <FooterOne/>
        </Loader>
    )
};

export default ContactUsPage;
