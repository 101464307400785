import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeadingSection from "../HeadingSection/HeadingSection";
// import testimonialsData from "../../data/Testimonials/testimonials-data.json";
import axios from "axios";
import {API_CONTENT_TESTIMONIAL} from "../../api/server";
import TestimonialItem from "./TestimonialItem";

const TestimonialsOne = ({title, tagline, font}) => {
    const settings = {
        dots: true,
        centerMode: true,
        centerPadding: "0",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "slick testimonial",
    };


    const [testimonialsData, setTestimonialData] = useState([])

    useEffect(() => {
        axios.get(API_CONTENT_TESTIMONIAL + "/").then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setTestimonialData(json.data.length > 0 ? json.data : [])
            }
        })
    }, [])

    useEffect(() => {
        console.log("TD", testimonialsData)
    }, [testimonialsData])

    return (
        <section
            className="parallax-bg-21 fixed-bg"
            data-stellar-background-ratio="0.2"
        >
            <div className="overlay-bg"></div>
            <div className="container">
                <div className="row">
                    <HeadingSection
                        title={title}
                        tagline={tagline}
                        classAppend="white-color"
                        font={font}
                    />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Slider {...settings}>
                            {
                                testimonialsData.map((v, i) => {
                                    return <TestimonialItem name={v.name} key={v.id} role={v.role} avatar={v.avatar_id}>
                                        {v.text}
                                    </TestimonialItem>
                                })
                            }
                            {/*{testimonialsData.map((testimonial) => (*/}
                            {/*    <TestimonialItem*/}
                            {/*        key={testimonial.id}*/}
                            {/*        avatar={testimonial.avatar}*/}
                            {/*        name={testimonial.name}*/}
                            {/*        role={testimonial.role}*/}
                            {/*    >*/}
                            {/*        {testimonial.text}*/}
                            {/*    </TestimonialItem>*/}
                            {/*))}*/}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialsOne;
