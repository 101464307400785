export const parseDate = (sqlDateString) => {
    try {
        let t = sqlDateString.replace("T", " ").replace("Z", "").split(/[- :+]/);
        // 2022-07-15 13:39:00
        // 0001-01-01T00:00:00+07:07
        // Apply each element to the Date function
        return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
    } catch (e) {
        return new Date(sqlDateString)
    }

}

export const dateIsToday = (date) => {
    const today = new Date()
    return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
}