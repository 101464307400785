import React from "react";

const CTAService = ({
                        tagline,
                        title,
                        bg,
                        textButton,
                        linkButton,
                        bgButton,
                        typeButton,
                        children,
                        brochureLink,
                        scheduleLink,
                    }) => (
    <section
        className={
            "pt-50 pb-50 cta-block " +
            (bg && bg === "dark" ? "service-dark-bg" : "default-bg")
        }
    >
        <div className="container">
            <div className="row">

                <div className="col-md-8">
                    <div className="cta-heading-left">
                        {tagline &&
                            <p className={"subtitle mt-20 " + (bg && bg === "dark" ? "use-primary" : "")}>{tagline}</p>}
                        {title && <h3 className={(bg && bg === "dark" ? "use-dark" : "")}>{title}</h3>}
                    </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-3">
                    <div className="cta-heading-right">
                        <p className="mt-30 float-right">
                            <a
                                href={linkButton}
                                target="_blank"
                                className={
                                    "btn btn-circle " +
                                    (bgButton || "btn-color ") +
                                    (typeButton || "")
                                }
                            >
                                {textButton}
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-12">
                    <div className="cta-heading-right">
                        <a
                            href={scheduleLink}
                            target="_blank"
                            className={
                                "btn btn-circle " +
                                (bgButton || "btn-color ") +
                                (typeButton || "")
                            }
                        >
                            Download Brochure
                        </a>
                        <a
                            href={brochureLink}
                            target="_blank"
                            className={
                                "btn btn-circle " +
                                (bgButton || "btn-color ") +
                                (typeButton || "")
                            }
                        >
                            Download Schedule
                        </a><br/>
                        <p className={"mt-20 " + (bg && bg === "dark" ? "content-text-dark" : "content-text")}>
                            {children}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </section>
);

export default CTAService;
