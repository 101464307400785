import React from "react";
import logoFooter from "../../assets/images/logo-footer.png";
import FooterCopyright from "./FooterCopyright";


const FooterOne = () => (
    <>
        <footer className="footer" id="footer-fixed">
            <div className="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <div className="widget widget-text">
                                <div className="logo logo-footer">
                                    <a href={`${process.env.PUBLIC_URL}/`}>
                                        <img
                                            className="logo logo-display"
                                            src={logoFooter}
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <p>
                                    PT. Pusat Pelatihan Kompetensi Mandiri (Welding Study Center) is an Authorized
                                    Training Body from Indonesia that is approved by International Institute of Welding
                                    (IIW). In providing services WSC cooperate with Indonesia Welding Society (API/IWS),
                                    KEMNAKER, BNSP, MIGAS, BKI, DNVGL, etc.
                                </p>
                            </div>
                        </div>
                        {/*<div className="col-sm-6 col-md-2">*/}
                        {/*  <div className="widget widget-links">*/}
                        {/*    <h5 className="widget-title">Work With Us</h5>*/}
                        {/*    <ul>*/}
                        {/*      <li>*/}
                        {/*        <a href="#!">Themeforest</a>*/}
                        {/*      </li>*/}
                        {/*      <li>*/}
                        {/*        <a href="#!">Audio Jungle</a>*/}
                        {/*      </li>*/}
                        {/*      <li>*/}
                        {/*        <a href="#!">Code Canyon</a>*/}
                        {/*      </li>*/}
                        {/*      <li>*/}
                        {/*        <a href="#!">Video Hive</a>*/}
                        {/*      </li>*/}
                        {/*      <li>*/}
                        {/*        <a href="#!">Envato Market</a>*/}
                        {/*      </li>*/}
                        {/*    </ul>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="col-sm-6 col-md-2">
                            <div className="widget widget-links">
                                <h5 className="widget-title">Useful Links</h5>
                                <ul>
                                    <li>
                                        <a href={`${process.env.PUBLIC_URL}/about`}>About Us</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.PUBLIC_URL}/contact`}>Contact Us</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.PUBLIC_URL}/service`}>Our Services</a>
                                    </li>
                                    {/*<li>*/}
                                    {/*  <a href={process.env.PUBLIC_URL}>Terms &amp; Conditions</a>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="widget widget-text widget-links">
                                <h5 className="widget-title">Contact Us</h5>
                                <ul>
                                    <li>
                                        <i className="icofont icofont-google-map"></i>
                                        <a href={`${process.env.PUBLIC_URL}/contact`}>
                                            Jl. Mercedes Benz No.5, Tlajung Udik, Kec. Gn. Putri,
                                            Kabupaten Bogor, Jawa Barat 16962
                                        </a>
                                    </li>
                                    <li>
                                        <i className="icofont icofont-iphone"></i>
                                        <a href="https://api.whatsapp.com/send/?phone=628111099894&text=Halo,%20mau%20tanya%20tentang%20WSC"
                                           target="_blank">+62
                                            811-1099-894</a>
                                    </li>
                                    <li>
                                        <i className="icofont icofont-mail"></i>
                                        <a href="mailto:marketing@weldingstudy.com">
                                            marketing@weldingstudy.com
                                        </a>
                                    </li>
                                    <li>
                                        <i className="icofont icofont-instagram"></i>
                                        <a href="https://www.instagram.com/weldingstudy/">@weldingstudy</a>
                                    </li>
                                    <li>
                                        <i className="icofont icofont-linkedin"></i>
                                        <a href="https://www.linkedin.com/company/welding-study-center/">Welding Study
                                            Center</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterCopyright/>
        </footer>
        <div className="footer-height" style={{height: "463px"}}></div>
    </>
);

export default FooterOne;
