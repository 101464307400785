import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleService from "../../components/PageTitle/PageTitleService";
import FooterOne from "../../components/Footer/FooterOne";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useNavigate} from "react-router-dom";
import CTAService from "./CTAService";
import axios from "axios";
import {API_CDN_DEFAULT, API_CONTENT_SERVICE} from "../../api/server";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../utils/Base64/request";
import {Col, Row} from "react-bootstrap";
import {CountPaginationOffset} from "../../utils/pagination";
import Pagination from "../../components/Pagination/Pagination";

const ServicePage = () => {
    const navigate = useNavigate()

    const scrollToSection = (e, content) => {
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/${content}`)
    };


    const [serviceData, setServiceData] = useState([])

    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        currentPage: 1,
        totalPage: 1,
        totalRows: 10
    })

    useEffect(() => {
        loadServiceData()
    }, [])

    const loadServiceData = (limit = pagination.rowsPerPage, offset = 0, filter = {}) => {
        axios.get(API_CONTENT_SERVICE + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setServiceData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setServiceData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        })
    }

    const [filterData, setFilterData] = useState("")

    const page = (e, currentPage) => {
        e.preventDefault();
        setPagination({...pagination, currentPage: currentPage});
    };

    const handleSearch = () => {
        loadServiceData(10, 0, {...filterData})
    }

    return <Loader>
        <HeaderTwo scrollToSection={scrollToSection}/>
        <PageTitleService title="Our Service"/>
        <section className="default-bg pt-80 pb-80">
            <div className={"container" + "wide"}>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <h5 style={{color: "white", width: "100%", textAlign: "center"}}>
                            Search Anything Here
                        </h5>
                    </Col>
                    <Col md={10}>
                        <div className="form-group">
                            <input
                                onChange={(e) => {
                                    setFilterData({
                                        ...filterData,
                                        title: e.target.value
                                    })
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Search Here"
                            />
                            <div className="help-block with-errors mt-20"></div>
                        </div>
                    </Col>
                    <Col md={3} className="align-items-center align-content-center justify-content-center">
                        <a href="#" className="btn btn-white btn-default" style={{width: "100%"}}
                           onClick={handleSearch}>
                            Search
                        </a>
                    </Col>
                </Row>

            </div>
        </section>

        {
            serviceData.map((service, index) => {
                return (
                    <CTAService
                        tagline="Our Service"
                        title={service.title}
                        bg={index % 2 === 0 ? "dark" : "default-bg"}
                        typeButton={index % 2 === 0 ? "btn-outline" : ""}
                        textButton="Apply Now"
                        bgButton={index % 2 !== 0 ? "btn-white" : ""}
                        linkButton={`https://wa.me/${service.apply_wa_number}?text=Halo Admin, Saya mau daftar di ${service.title}`}
                        brochureLink={API_CDN_DEFAULT + "/download?load=" + service.brochure_file_id}
                        scheduleLink={API_CDN_DEFAULT + "/download?load=" + service.schedule_file_id}
                    >

                        {service.description}
                    </CTAService>
                )
            })
        }

        <Pagination
            postsPerPage={pagination.rowsPerPage}
            totalPosts={pagination.totalRows}
            page={page}
            currentPage={pagination.currentPage}
            onPageChange={(newPage) => {
                loadServiceData(pagination.rowsPerPage, CountPaginationOffset(newPage, pagination.rowsPerPage))
            }}
        />


        {/*<CTAService*/}
        {/*    tagline="Our Service"*/}
        {/*    title="Service A"*/}
        {/*    bg="dark"*/}
        {/*    typeButton="btn-outline"*/}
        {/*    textButton="Apply Now"*/}
        {/*    linkButton="https://wa.me/6282237702305?text=Halo Admin, Saya mau daftar di service ABC"*/}
        {/*>*/}

        {/*    We do not tell you our story. We write it together. Partnering with us*/}
        {/*    means a seat at the table where you will be heard.*/}
        {/*</CTAService>*/}
        {/*<CTAService*/}
        {/*    tagline="Our Service"*/}
        {/*    title="Service B"*/}
        {/*    textButton="Apply Now"*/}
        {/*    linkButton="https://wa.me/6282237702305?text=Halo Admin, Saya mau daftar di service ABC"*/}
        {/*    bgButton="btn-white"*/}
        {/*>*/}
        {/*    We do not tell you our story. We write it together. Partnering with us*/}
        {/*    means a seat at the table where you will be heard.*/}
        {/*</CTAService>*/}
        <FooterOne/>
    </Loader>
}

export default ServicePage;
