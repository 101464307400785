import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleBlog from "../../components/PageTitle/PageTitleBlog";
import EventsContent from "./EventsContent";
import FooterOne from "../../components/Footer/FooterOne";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useNavigate} from "react-router-dom";

const EventsPage = () => {
    const navigate = useNavigate()

    const scrollToSection = (e, content) => {
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/${content}`)
    };
    
    return <Loader>
        <HeaderTwo scrollToSection={scrollToSection}/>
        <PageTitleBlog
            title="Our Events"
            tagline="Checkout Our Latest Events"
        />
        <EventsContent type="sidebar-right"/>
        <FooterOne/>
    </Loader>
};

export default EventsPage;
