import React from "react";
import {parseDate} from "../../utils/date";

const SidebarForEvents = ({eventPost}) => (
    <>
        <div className="widget sidebar_widget widget_archive">
            <h4 className="widget-title">Details</h4>
            <table>
                <tr style={{height: "40px"}}>
                    <td width="40%"><b>Start</b></td>
                    <td>{parseDate(eventPost.event_started_date).toDateString()}</td>
                </tr>
                <tr>
                    <td width="40%"><b>End</b></td>
                    <td>{parseDate(eventPost.event_end_date).toDateString()}</td>
                </tr>
            </table>
        </div>
        <div className="widget sidebar_widget widget_archive">
            <h4 className="widget-title">Organizer</h4>
            <table>
                <tr style={{height: "40px"}}>
                    <td width="40%"><b>Phone</b></td>
                    <td>+{eventPost.event_organizer_phone_number}</td>
                </tr>
            </table>
        </div>
        <div className="widget sidebar_widget widget_archive">
            <h4 className="widget-title">Venue</h4>
            <p>
                {eventPost.event_venue}
            </p>
        </div>
    </>
);

export default SidebarForEvents;
