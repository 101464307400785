import React from 'react'
import SanitizedHTML from "react-sanitized-html";

export const CkHTMLComponent = ({html}) => {
    return (
        <div className={"ck-content p-reset-lineheight"}>
            <SanitizedHTML
                allowedAttributes={{
                    'a': ['href'],
                    'img': ['src'],
                    'figure': ['class'],
                    'p': ['style'],
                    'oembed': ['url'],
                    'div': ['data-oembed-url', 'style'],
                    'iframe': ['src', 'frameborder', 'allow', 'allowfullscreen', 'style']
                }}
                allowedTags={['div', 'iframe', 'blockquote', 'b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'li', 'br', 'img', 'figure', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'oembed', 'ol']}
                html={html}
            />
        </div>
    )
}