import React from "react";

const SidebarForEvents = () => (
    <>
        <div className="widget sidebar_widget">
            <form className="search-form" method="get">
                <input
                    type="text"
                    name="name"
                    className="form-control search-field"
                    id="search"
                    placeholder="Type what it's your mind..."
                />
                <button
                    type="submit"
                    className="icofont icofont-search-1 search-submit"
                ></button>
            </form>
        </div>
        <div className="widget widget_about">
            <h4 className="widget-title">About Events</h4>
            <p>
                Welding Study Center Conduct Training and Engineering Service:
            </p>
            <p>
                <b>Training:</b> <br/>
                - International Welding Engineer (IIW Scheme) <br/>
                - International Welding Technologist (IIW Scheme) <br/>
                - International Welding Inspector Personel (B-S-C) (IIW Scheme) <br/>
                - International Welding Practitioner (IIW Scheme) <br/>
                - International Welding Specialist (IIW Scheme) <br/>
                - International Welder (IIW Scheme) <br/>
                - NDT ASNT MT/PT Level II <br/>
                - NDT ASNT UT Level II <br/>
                - NDT ASNT RT Level II <br/>
                - NDT ASNT PAUT (Phased Array Ultrasonic) <br/>
                - NDT ASNT Eddy Current <br/>
                - API 510 Preparation (Preassure Vessel Inspector) <br/>
                - API 570 Preparation (Piping Inspector) <br/>
                - API 571 Preparation (Corrosion and Materials Professional) <br/>
                - API 577 Preparation (Welding Inspection and Metallurgy) <br/>
                - API 580 Preparation (Risk Based Inspection) <br/>
                - API 653 Preparation (Aboveground Storage Tank Inspector) <br/>
                - API 936 Preparation (Refractory Personel) <br/>
            </p>
            <p>
                <b>Engineering Service:</b> <br/>
                - WPS (Welding Procedure Specification) Approved by MIGAS & KEMNAKER <br/>
                - PQR (Procedure Qualification Record) Approved by MIGAS & KEMNAKER <br/>
                - WQT (Welder Qualification Test) Approved by MIGAS, BNSP, & KEMNAKER <br/>
                - Failure Analysis <br/>
                - Metallurgical Project Management <br/>
                - Metallurgical Consultation= <br/>
            </p>
        </div>
        {/*<div className="widget sidebar_widget widget_archive">*/}
        {/*    <h4 className="widget-title">Date</h4>*/}
        {/*    <ul>*/}
        {/*        <li>*/}
        {/*            <a href={process.env.PUBLIC_URL}>January 2017</a>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*            <a href={process.env.PUBLIC_URL}>May 2016</a>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*            <a href={process.env.PUBLIC_URL}>June 2015</a>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*            <a href={process.env.PUBLIC_URL}>Febuary 2017</a>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*            <a href={process.env.PUBLIC_URL}>April 2015</a>*/}
        {/*        </li>*/}
        {/*    </ul>*/}
        {/*</div>*/}
    </>
);

export default SidebarForEvents;
