import React from "react";
import ContactFormFive from "./ContactFormFive";

const ContactThree = () => (
    <section className="contact-us dark-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <ContactFormFive/>
                </div>
                <div className="col-md-4">
                    <h3 className="white-color">Place </h3>
                    <address>
                        Jl. Mercedes Benz No. 5, Tlajung Udik, Kec. Gn. Putri,
                        Kabupaten Bogor, Jawa Barat 16962
                        <br/>
                        <abbr title="Phone">Phone:</abbr> (+62) 811-1099-894
                        <br/>
                        <a href="mailto:#">marketing@weldingstudy.com</a>
                    </address>
                    <h3 className="white-color">Social Media</h3>
                    <address>
                        <abbr title="Instagram">Instagram:</abbr> @weldingstudy
                        <br/>
                        <abbr title="Linked.in">Linkedln:</abbr> Welding Study Center
                        <br/>
                    </address>
                    <h3 className="white-color">Work Timings</h3>
                    <p>
                        <span>Mon - Fri: 10am to 7pm</span> <br/>
                        <span>Sat : 8am to 12pm</span>
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default ContactThree;
