import React from "react";
import {API_CDN_DEFAULT} from "../../api/server";

const TestimonialItem = ({avatar, name, role, children}) => (
    <div className="testimonial-item">
        <div className="testimonial-content">
            <img
                className="img-fluid rounded-circle"
                src={avatar !== undefined && avatar !== null ? `${API_CDN_DEFAULT}/?load=${avatar}` : require("../../assets/images/team/avatar-1.jpg")}
                alt="avatar-1"
            />
            <h5>{name}</h5>
            <p>{role}</p>
            <h4>{children}</h4>
        </div>
    </div>
);

export default TestimonialItem;
