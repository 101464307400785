import React from "react";
import serviceImg from "../../assets/images/wsc/about-us-1.png";

const OurServicesThree = ({title, tagline}) => (
    <section className="white-bg">
        <div className="col-md-6 col-sm-4 bg-flex bg-flex-right">
            <div
                className="bg-flex-holder bg-flex-cover"
                style={{backgroundImage: `url(${serviceImg})`}}
            ></div>
        </div>
        <div className="container">
            <div className="col-md-5 col-sm-7">
                <h2 className="font-700">{title}</h2>
                <h4 className="mt-10 line-height-26 default-color">{tagline}</h4>
                <p className="mt-20">
                    Welding Study Center is an Authorized Training Body from Indonesia that is approved by International
                    Institute of Welding (IIW) to conduct Training and Certification for International Welding Engineer,
                    International Welding Inspector (B-S-C), International Welding Technologist, International Welding
                    Specialist, International Welding Practitioner, International Welding Inspector Transition
                    Arrangement, and International Welder. In providing Training and Certification services WSC
                    cooperate with Indonesia Welding Society (API/IWS), KEMNAKER, BNSP, MIGAS, BKI, DNVGL, etc.
                </p>
                <p>
                    WSC is a subsidiary of SaVa Group – also holding company for PT. Gamma Buana Persada. For a person
                    who wants
                    to be professionally certified and expert in welding, metallurgical engineering, inspection and
                    whole life integrity management. Welding Study Center one of the independent international training
                    body and research technology organizations by continuing span of innovation. With our expert and
                    experienced professional sources to share knowledge and to improve the people's skill and provide
                    the best solution.
                </p>
                {/*<p className="mt-30">*/}
                {/*    <a*/}
                {/*        href={`${process.env.PUBLIC_URL}/service-simple`}*/}
                {/*        className="btn btn-color btn-circle"*/}
                {/*    >*/}
                {/*        Read More*/}
                {/*    </a>*/}
                {/*</p>*/}
            </div>
        </div>
    </section>
);

export default OurServicesThree;
