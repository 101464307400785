import React from "react";
import Loader from "../../components/Loader/Loader";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import WhoWeAreSeven from "../../components/WhoWeAre/WhoWeAreSeven";
import OurSkillsTwo from "../../components/OurSkills/OurSkillsTwo";
import CounterOne from "../../components/Counters/CounterOne";
import OurServicesThree from "../../components/OurServices/OurServicesThree";
import OurTeamTwo from "../../components/Team/OurTeamTwo";
import TestimonialsOne from "../../components/Testimonials/TestimonialsOne";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useNavigate} from "react-router-dom";

const AboutUsPage = () => {
    const navigate = useNavigate()

    const scrollToSection = (e, content) => {
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/${content}`)
    };

    return (
        <Loader>
            <HeaderTwo scrollToSection={scrollToSection}/>
            <PageTitleAbout
                title="About Us"
                tagline="Welding Study Center Since 1999"
            />
            <WhoWeAreSeven/>
            <OurSkillsTwo
                title="Our Client Sector"
                tagline="We develop big ideas that sell"
            />
            <CounterOne type="wide"/>
            <OurServicesThree
                title="About Us"
                tagline="Part of your success"
            />
            <OurTeamTwo title="WSC Team" tagline="WE ARE STRONGER"/>
            <TestimonialsOne
                title="Testimonials"
                tagline="Happy clients"
            />
            <ClientsBrand/>
            <FooterOne/>
        </Loader>
    )
};

export default AboutUsPage;
