import React from "react";
import {Link} from "react-router-dom";
import {API_CDN_DEFAULT} from "../../api/server";

const EventsItemSidebar = ({
                               id,
                               index,
                               image,
                               title,
                               published,
                               event_date,
                               link
                           }) => {
    const eventsURL = `/events/${title
        .replace(/\//g, "-")
        .replace(/\s/g, "-")
        .toLocaleLowerCase()}?id=${id}`;

    return (
        <div
            className={
                "post " +
                ((index === 0)
                    ? ""
                    : (index === 0)
                        ? ""
                        : "mt-50")
            }
        >
            <div className="post-img">
                <img
                    className="img-fluid"
                    src={`${API_CDN_DEFAULT}?load=${image}`}
                    alt=""
                />
            </div>
            <div className="post-info">
                <h3>
                    <Link to={link}>{title}</Link>
                </h3>
                <h6>Event Date: {event_date}</h6>
                <p>Posted on: {published}</p>
            </div>
        </div>
    );
};

export default EventsItemSidebar;
