import React, {useEffect, useState} from "react";
import Pagination from "../../components/Pagination/Pagination";
import EventsWrapper from "./EventsWrapper";
import EventsItemSidebar from "./EventsItemSidebar";
import SidebarForEvents from "./SidebarForEvents";
import axios from "axios";
import {API_CONTENT_EVENT} from "../../api/server";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../utils/Base64/request";
import {parseDate} from "../../utils/date";
import {CountPaginationOffset} from "../../utils/pagination";

const EventsContent = ({type, sidebar}) => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    const [pagination, setPagination] = useState({
        rowsPerPage: 6,
        page: 1,
        totalRows: 6
    })

    const page = (e, currentPage) => {
        e.preventDefault();
        setCurrentPage(currentPage);
    };

    useEffect(() => {
        loadEventPostData()
    }, []);

    const loadEventPostData = (count = pagination.rowsPerPage, offset = 0, filter = {}) => {

        axios.get(API_CONTENT_EVENT + "/?pagination=" + CreatePaginationEncoding(count, offset) + "&filter=" + CreateFilterEncoding({...filter})).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                if (json.data.length > 0) {
                    // console.log("EPD", json.data)
                    setPosts([...json.data])
                    setPagination({
                        ...pagination,
                        totalRows: json.count.all
                    })
                    // setData(json.data[0])
                    // setEditorData(json.data[0].event_content)
                }
            }
        })
    }

    return (
        <section
            className={type === "grid" || type === "masonry" ? "pt-100 pb-100" : null}
        >
            <div className="container">
                <div className="row">
                    <EventsWrapper type={type}>
                        {posts.map((post, index) => {
                            return (
                                <EventsItemSidebar
                                    key={post.id}
                                    id={post.id}
                                    index={index}
                                    // firstPost={indexOfFirstPost}
                                    image={post.cover_image_id}
                                    title={post.title}
                                    link={`${process.env.PUBLIC_URL}/events/${post.slug}`}
                                    slides={null}
                                    published={parseDate(post.posted_date).toLocaleDateString()}
                                    event_date={parseDate(post.event_started_date).toLocaleDateString()}
                                    excerpt={post.excerpt}
                                    type={post.type}
                                />
                            )
                        })}
                    </EventsWrapper>
                    {type === "sidebar-right" || type === "sidebar" ? (
                        <div className="col-md-3 offset-md-1 right-col-rv">
                            <SidebarForEvents/>
                        </div>
                    ) : null}
                </div>
                <Pagination
                    postsPerPage={pagination.rowsPerPage}
                    totalPosts={pagination.totalRows}
                    page={page}
                    currentPage={currentPage}
                    onPageChange={(newPage) => {
                        loadEventPostData(pagination.rowsPerPage, CountPaginationOffset(newPage, pagination.rowsPerPage))
                    }}
                />
            </div>
        </section>
    );
};

export default EventsContent;
