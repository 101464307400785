import React, {useEffect, useState} from "react";
import dataBlog from "../../data/Blog/blog-data.json";
import dataSocial from "../../data/Social/social-data.json";
import Loader from "../../components/Loader/Loader";
import PageTitleBlog from "../../components/PageTitle/PageTitleBlog";
import Icofont from "react-icofont";
import SidebarForEventDetails from "./SidebarForEventDetails";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {API_CDN_DEFAULT, API_CONTENT_EVENT} from "../../api/server";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../utils/Base64/request";
import {parseDate} from "../../utils/date";
import {CkHTMLComponent} from "../../components/Others/CkHTMLComponent";

const EventsDetailPage = ({sidebar}) => {
    const postId = new URLSearchParams(window.location.search).get("id");
    const post = dataBlog.find((post) => post.id === parseInt(postId));
    const featuredPost = dataBlog.find((post) => post.id === 1);

    const navigate = useNavigate()

    const {slug} = useParams()

    const scrollToSection = (e, content) => {
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/${content}`)
    };

    const [eventPost, setEventPost] = useState({
        id: 0,
        created_at: new Date().toISOString(),
        posted_date: new Date().toISOString(),
        event_started_date: new Date().toISOString(),
        event_end_date: new Date().toISOString(),
        title: "",
        event_organizer_phone_number: "",
        event_venue: "",
        event_content: "",
        event_note: "",
        slug: "",
        cover_image_id: "",
    })

    const loadEventPostData = () => {
        axios.get(API_CONTENT_EVENT + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
            slug: slug
        })).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                if (json.data.length > 0) {
                    setEventPost(json.data[0])
                }
            }
        })
    }

    useEffect(() => {
        loadEventPostData()
    }, [slug])

    return (
        <Loader>
            <HeaderTwo scrollToSection={scrollToSection}/>
            <PageTitleBlog title={eventPost.title}
                           tagline={`Posted on, ${parseDate(eventPost.posted_date).toLocaleDateString('en-GB')}`}/>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="post">
                                <div className="post-img">
                                    <img
                                        className="img-fluid"
                                        src={`${API_CDN_DEFAULT}?load=${eventPost.cover_image_id}`}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="blog-standard">
                                <CkHTMLComponent html={eventPost.event_content}/>
                                <blockquote>
                                    <p>{eventPost.event_note}</p>
                                </blockquote>
                            </div>
                            <div className="post-controls">
                                <div className="post-share">
                                    <ul>
                                        <li> Share:</li>
                                        {dataSocial.map((social, i) => (
                                            <li key={i}>
                                                <a href={social.link}>
                                                    <Icofont icon={social.icon}/>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 offset-md-1 right-col-rv">
                            <SidebarForEventDetails eventPost={eventPost}/>
                        </div>
                    </div>
                </div>
            </section>
        </Loader>
    );
};

export default EventsDetailPage;
