import React from "react";
// import ReactWow from "react-wow";
import HeadingSection from "../HeadingSection/HeadingSection";
import aboutData from "../../data/About/about-home-agency-data.json";

const WhoWeAreSeven = () => (
    <section className="first-ico-box">
        <div className="container">
            <div className="row">
                <HeadingSection
                    title="Who We Are"
                    tagline="The world at your fingertips"
                >
                    Welding Study Center is an Authorized Training Body from Indonesia that is approved by International
                    Institute of Welding (IIW) to conduct Training and Certification for International Welding Engineer,
                    International Welding Inspector (B-S-C), International Welding Technologist, International Welding
                    Specialist, International Welding Practitioner, International Welding Inspector Transition
                    Arrangement, and International Welder. In providing Training and Certification services WSC
                    cooperate with Indonesia Welding Society (API/IWS), KEMNAKER, BNSP, MIGAS, BKI, DNVGL, etc. WSC is a
                    subsidiary of SaVa Group – also holding company for PT. Gamma Buana Persada. For a person who wants
                    to be professionally certified and expert in welding, metallurgical engineering, inspection and
                    whole life integrity management. Welding Study Center one of the independent international training
                    body and research technology organizations by continuing span of innovation. With our expert and
                    experienced professional sources to share knowledge and to improve the people's skill and provide
                    the best solution.
                </HeadingSection>
            </div>
            <div className="row mt-50 justify-content-center">
                {aboutData.map((service, i) => {
                    return (
                        // <ReactWow
                        //   key={service.id}
                        //   animation="fadeTop"
                        //   delay={`0.${i + 1}s`}
                        // >
                        <div className="col-md-4 feature-box text-center radius-icon mb-30">
                            {/*<Icofont icon={service.icon} className="font-50px dark-icon"/>*/}
                            <img src={require("../../assets/images/about-us-wwa/" + service.icon)} alt={"image"}
                                 style={{
                                     height: "150px"
                                 }}/>
                            <h4 className="text-uppercase">{service.title}</h4>
                            <p>{service.text}</p>
                        </div>
                        // </ReactWow>
                    );
                })}
            </div>
        </div>
    </section>
);

export default WhoWeAreSeven;
