import React from "react";
import {Map as PGMap, Marker} from "pigeon-maps";

const Map = ({classAppend}) => {
    const defaultProps = {
        center: {
            lat: -6.4441838,
            lng: 106.9159898,
        },
        zoom: 16,
    };

    const mapOptions = {
        panControl: true,
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: true,
        scrollwheel: false,
        styles: [
            {
                elementType: "geometry",
                stylers: [
                    {
                        color: "#f5f5f5",
                    },
                ],
            },
            {
                elementType: "labels.icon",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161",
                    },
                ],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#f5f5f5",
                    },
                ],
            },
            {
                featureType: "administrative.country",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#1ec0ff",
                    },
                ],
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#bdbdbd",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#eeeeee",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#e5e5e5",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#ffffff",
                    },
                ],
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#dadada",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161",
                    },
                ],
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e",
                    },
                ],
            },
            {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#e5e5e5",
                    },
                ],
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#eeeeee",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#c9c9c9",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e",
                    },
                ],
            },
        ],
    };

    return (
        <div id="myMap" className={classAppend ? classAppend : ""}>
            <PGMap defaultCenter={[-6.4274841, 106.9288266]} defaultZoom={16}>
                <Marker width={50} anchor={[-6.4274841, 106.9288266]}/>
            </PGMap>

            {/*<GoogleMap*/}
            {/*    defaultCenter={defaultProps.center}*/}
            {/*    defaultZoom={defaultProps.zoom}*/}
            {/*    bootstrapURLKeys={{*/}
            {/*        key: 'AIzaSyAAESeBo8OD4y6cMMxfe4IdNLJEJwz5s6U'*/}
            {/*    }}*/}
            {/*    options={mapOptions}*/}
            {/*>*/}
            {/*    <Marker*/}
            {/*        icon={pinImg}*/}
            {/*        position={{lat: -73.995763, lng: 40.716304}}*/}
            {/*    />*/}
            {/*</GoogleMap>*/}
        </div>
    );
};

export default Map;
