import React, {useEffect, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FsLightbox from "fslightbox-react";
import Loader from "./../../components/Loader/Loader";
import dataMinimal from "../../data/Slider/minimal-design-data.json";
import HeroSliderCombined from "../../components/Hero/HeroSliderCombined";
import WelcomeTwo from "../../components/WelcomeSection/WelcomeTwo";
import CTATwo from "../../components/CTA/CTATwo";
import TestimonialsOne from "../../components/Testimonials/TestimonialsOne";
import FooterOne from "../../components/Footer/FooterOne";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useNavigate} from 'react-router-dom';

const HomePage = () => {
    const [toggler, setToggler] = useState(false);

    const handleToggler = () => {
        setToggler(!toggler);
    };

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const navigate = useNavigate()

    const scrollToSection = (e, content) => {
        e.preventDefault();
        navigate(`${process.env.PUBLIC_URL}/${content}`)
    };


    return (
        <Loader>
            <FsLightbox
                toggler={toggler}
                sources={["https://www.youtube.com/watch?v=tXsM46TIk3A"]}
            />
            <HeaderTwo scrollToSection={scrollToSection}/>
            <HeroSliderCombined data={dataMinimal} font="cardo-font"/>
            <WelcomeTwo toggler={toggler} handleToggler={handleToggler}/>
            <TestimonialsOne
                title="Testimonials"
                tagline="Happy clients"
                font="cardo-font"
            />
            {/*<Portfolio columns="3" layout="wide" items="6" classAppend="pt-0" />*/}
            {/*<TabsIconSectionTwo title="What We Offer" font="cardo-font"/>*/}
            <CTATwo
                title="Let's Work Together"
                tagline="-- -- --"
                textButton="Let's talk"
                linkButton={`${process.env.PUBLIC_URL}/contact`}
            >
                Our Vision <br/>

                Being a Vocational Training Specialist
                company at the advanced in the field of
                Welding, NDT, Metallurgy, Metal Processing
                and Project Management
                <br/><br/>
                Our Mission<br/>

                Prepare high capable and skillfull person in
                the field of Welding, NDT, Metallurgy, Metal
                Processing and Project Management
            </CTATwo>
            <FooterOne/>
        </Loader>
    );
};

export default HomePage