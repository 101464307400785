import React, {forwardRef, useEffect, useRef, useState} from "react";
import dataPortfolio from "../../data/Portfolio/galerry-data.json";
import PortfolioFilter from "../../elements/Portfolio/PortfolioFilter";
import PortfolioItem from "./PortfolioItem";
import Shuffle from "shufflejs";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

const Portfolio = forwardRef(
    ({filter, layout, columns, space, items, classAppend, children}, ref) => {
        const categories = ["all", "Training International Welder", "Training International Welding Engineer", "Training International Welding Inspector Personel", "Training NDT ASNT Level II"];
        const element = useRef();
        const [shuffle, setShuffle] = useState();

        useEffect(() => {
            if (element.current) {
                setShuffle(
                    new Shuffle(element.current, {
                        itemSelector: ".portfolio-item",
                    })
                );
            }


        }, []);

        useEffect(() => {
            // if (shuffle !== undefined && shuffle !== null) {
            //     const el = document.getElementsByClassName("active list-inline-item")
            //     for (let i = 0; i < el.length; i++) {
            //         new Promise(resolve => setTimeout(resolve, 50)).then(r => el[i].click());
            //     }
            // }

        }, [shuffle])

        const filterElements = (evt) => {
            const btn = evt.currentTarget;
            evt.target.parentElement
                .querySelectorAll(".active")
                .forEach((e) => e.classList.remove("active"));
            evt.currentTarget.classList.add("active");
            const cat = btn.getAttribute("value");
            shuffle.filter((element) => {
                return element.getAttribute("data-groups").toLowerCase().includes(cat.toLowerCase());
            });
        };

        const [isOpen, setIsOpen] = useState(false);
        const [photo, setPhoto] = useState(0);

        const closeLightbox = () => {
            setIsOpen(false);
        };
        const openLightbox = (e, photo) => {
            e.preventDefault();
            setPhoto(photo);
            setIsOpen(true);
        };

        return (
            <section
                className={"pb-0 " + (classAppend ? classAppend : "")}
                id="work"
                ref={ref}
            >
                {children ? (
                    <div className="container">
                        <div className="row">{children}</div>
                    </div>
                ) : null}
                <div className={"container" + (layout === "wide" ? "-fluid" : "")}>
                    <div className="row">
                        <div className={"container" + (layout === "wide" ? "-fluid" : "") + " text-center"}>
                            {filter === "true" ? (
                                <PortfolioFilter
                                    categories={categories}
                                    handleClick={filterElements}
                                />
                            ) : null}

                            <div
                                id="portfolio-grid"
                                ref={element}
                                className="hover-two row"
                            >
                                {dataPortfolio.map((item, i) => (
                                    <PortfolioItem
                                        key={item.title + i}
                                        title={item.title}
                                        category={item.category}
                                        image={item.image}
                                        groups={["all", item.category]}
                                        space={space ? "true" : "false"}
                                        columns={columns}
                                        openLightbox={openLightbox}
                                    />
                                ))}
                            </div>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={require("../../assets/images/" + photo)}
                                    onCloseRequest={() => closeLightbox()}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
);

export default Portfolio;
