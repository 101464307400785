import React, {useEffect, useState} from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
// import countersData from "../../data/Counters/counter-data2.json";
import axios from "axios";
import {API_CONTENT_CONFIGURATOR} from "../../api/server";
import {CreateFilterEncoding} from "../../utils/Base64/request";

const CountersThree = ({type}) => {
    const [viewed, setViewed] = useState(false);

    const viewChangeHandler = (isVisible) => {
        if (isVisible) setViewed(true);
    };


    const [countersData, setCountersData] = useState([])


    useEffect(() => {
        axios.get(API_CONTENT_CONFIGURATOR + "/?filter=" + CreateFilterEncoding({
            key: "CONF_CONTENT_STATISTIC"
        }), {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            json.data && json.data.data.length > 0 && setCountersData([...JSON.parse(json.data.data[0].value)])
        })
    }, [])

    return (
        <section className="default-bg pt-80 pb-80">
            <div className={"container" + (type === "wide" ? "-fluid" : "")}>
                <div className="row">
                    {countersData.map((counter, i) => (
                        <div
                            className="col-md-3 counter text-center col-sm-6"
                            key={counter.id}
                            data-aos={"fade-up"}
                            data-aos-delay={`${i}00`}
                            data-aos-duration={1000}
                        >
                            <h2 className="count white-color font-700">
                                <VisibilitySensor onChange={viewChangeHandler} delayedCall>
                                    <CountUp end={viewed ? counter.value : 0}/>
                                </VisibilitySensor>
                            </h2>
                            <h3>{counter.title}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CountersThree;
