import React from 'react'
import HomePage from "../pages/HomePage";
import AboutUsPage from "../pages/AboutPage";
import GalleryPage from "../pages/GalleryPage";
import ContactUsPage from "../pages/ContactUsPage";
import EventsPage from "../pages/EventsPage";
import EventsDetailPage from "../pages/EventsDetailPage";
import ServicePage from "../pages/ServicePage";

export const linkRoutes = [
    {path: `${process.env.PUBLIC_URL}/`, Component: <HomePage/>},
    {path: `${process.env.PUBLIC_URL}/about`, Component: <AboutUsPage/>},
    {path: `${process.env.PUBLIC_URL}/gallery`, Component: <GalleryPage/>},
    {path: `${process.env.PUBLIC_URL}/contact`, Component: <ContactUsPage/>},
    {path: `${process.env.PUBLIC_URL}/events`, Component: <EventsPage/>},
    {path: `${process.env.PUBLIC_URL}/events/:slug`, Component: <EventsDetailPage/>},
    {path: `${process.env.PUBLIC_URL}/service`, Component: <ServicePage/>},
]