import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import ScrollToTop from "../helpers/ScrollToTop";
import {linkRoutes} from "./routes";


const Routers = () => {

    useEffect(() => {
        let abortController = new AbortController();
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        return () => {
            abortController.abort();
        };

    }, []);

    return (
        <BrowserRouter>
            <>
                <ScrollToTop>
                    <Routes>
                        {linkRoutes.map(({path, Component}, i) => (
                            <Route path={path} element={Component} key={i}/>
                        ))}
                    </Routes>
                </ScrollToTop>
                {/*<Suspense fallback={<Loader/>}>*/}
                {/*    <Routes>*/}
                {/*        <Route path={`/*`} element={<LayoutRoutes/>}/>*/}
                {/*        {unprotectedRoutes.map(({path, Component}, i) => (*/}
                {/*            <Route path={path} element={Component} key={i}/>*/}
                {/*        ))}*/}
                {/*    </Routes>*/}
                {/*</Suspense>*/}
            </>
        </BrowserRouter>
    );
};

export default Routers;