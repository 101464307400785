import React, {useEffect, useState} from "react";
import Icofont from "react-icofont";

const ScrollToTop = ({children}) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll, {passive: true});

        return () => window.removeEventListener("scroll", handleScroll);
    }, [scrolled]);

    const backToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <a
                href="https://api.whatsapp.com/send/?phone=628111099894&text=Halo,%20mau%20tanya%20tentang%20WSC"
                target="_blank"
                id="back-to-top"
                className={"show"}
                title="Contact Us"
                // onClick={backToTop}
            >
                <Icofont icon="brand-whatsapp"/>
            </a>
            {children}
        </>
    );
};

export default ScrollToTop;
